<template>
  <div class="layout">
    <!-- 头部背景图片 -->
    <div class="layout_bg"></div>
    <div class="layout_content">
      <!-- 头部信息 -->
      <div class="layout_content_head">
        <div class="layout_content_head_day">
          {{ getCurrentDate() }}
        </div>
        <div class="layout_content_head_banner">
          <el-carousel height="70px" autoplay arrow="never">
            <el-carousel-item v-for="(item, index) in bannerList" :key="index">
              <img @click="jumpBanner(item)" :src="item.smallimage" alt="banner" class="resize-image"/>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="layout_content_head_user" @click="openUser">
          <div class="user_img">
            <img :src="user.avatar" alt="avatar"/>
          </div>
          <div class="user_info">
            <span>{{ user.nickname }}</span>
            <div class="user_info_ststus">
              <img src="@/assets/iconPark-vip3x@2x.png" alt=""/>
              <div class="user_info_ststus_border">
                <span>已认证</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 图标数据 -->
      <div class="layout_content_picture">
        <div class="layout_content_picture_item">
          <div class="layout_content_picture_item_head">
            <div class="layout_content_picture_item_head_title">
              <img src="@/assets/jian@2x.png" alt=""/>
              <span v-if="threeName.length >= 1">{{ threeName[0].name }}</span>
            </div>
            <div style="display: flex; align-items: center;">
            </div>
          </div>
          <div class="layout_content_picture_item_tu">
            <lineElement id="line1" ref="LineElement1"/>
          </div>
        </div>
        <div class="layout_content_picture_item">
          <div class="layout_content_picture_item_head">
            <div class="layout_content_picture_item_head_title">
              <img src="@/assets/jian@2x.png" alt=""/>
              <span v-if="threeName.length >= 2">{{ threeName[1].name }}</span>
            </div>
            <div style="display: flex; align-items: center;">
            </div>
          </div>
          <div class="layout_content_picture_item_tu">
            <lineElement id="line2" ref="LineElement2"/>
          </div>

        </div>
        <div class="layout_content_picture_item">
          <div class="layout_content_picture_item_head">
            <div class="layout_content_picture_item_head_title">
              <img src="@/assets/jian@2x.png" alt=""/>
              <span v-if="threeName.length >= 3">{{ threeName[2].name }}</span>
            </div>
            <div style="display: flex; align-items: center;">
            </div>
          </div>
          <div class="layout_content_picture_item_tu">
            <lineElement id="line3" ref="LineElement3"/>
          </div>
        </div>
      </div>
      <!-- 主体内容区域 -->
      <div class="layout_content_box">
        <!-- 菜单栏 -->
        <div class="layout_content_box_menu">
          <div class="menu_logo">
            <img :src="sysconfig.web_logo" alt=""/>
          </div>
          <div class="menu_top">
            <div class="menu_top_item" v-for="(item, index) in menu" :key="index"
                 @click="item.children.length == 0 ? clickItem(item.id, item.name, item.id) : ''">
              <span class="menu_top_item_text">{{ item.name }}</span>

              <div class="menu_top_item_list" v-if="item.children.length > 0">
                <div class="menu_top_item_list_item" v-for="(child, i) in item.children" :key="i"
                     @click="child.children.length == 0 ? clickItem(child.id, `${item.name} - ${child.name}`, item.id) : ''">
                  <span class="menu_top_item_list_item_text">{{ child.name }}</span>

                  <div class="menu_top_item_list_list" v-if="child.children.length > 0">
                    <div class="menu_top_item_list_list_item" v-for="(child1, ii) in child.children" :key="ii"
                         @click="child1.children.length == 0 ? clickItem(child.id, `${item.name} - ${child.name} - ${child1.name}`, item.id) : ''">
                      <span class="menu_top_item_list_list_item_text">{{ child1.name }}</span>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
        <!-- 内容区域 -->
        <div class="layout_content_box_nr">
          <!-- 输入框 -->
          <template v-if="typeView == 1 || typeView == 3 || typeView == 5">
            <textareaComponent :typeView="typeView" :channel="childMenuId" @result="sendMessageResult"/>
          </template>
          <!-- 论坛/新闻 -->
          <template v-if="(typeView == 1 || typeView == 3 || typeView == 5) && childMenuId">
            <stickView :channel="childMenuId" :typeView="typeView" :create_time="dateYMD" ref="stickRef"
                       :timeVal="timeVla" :title="title" @finish="reset"/>
          </template>
          <!-- 投研日志 -->
          <template v-if="typeView == 7">
            <logView :title="title" :channel="childMenuId" :create_time="dateYMD" ref="logRef"/>
          </template>
          <!-- 视频 -->
          <template v-if="typeView == 4">
            <VideoView :title="title" :channel="childMenuId" :create_time="dateYMD" ref="videoRef" :timeVal="timeVla"
                       @finish="reset"/>
          </template>
          <!-- 量化信号 -->
          <template v-if="typeView == 2">
            <chartList :title="title" :timeVal="timeVla" :channel="childMenuId" @finish="reset" ref="quantization"/>
          </template>
        </div>
        <div class="layout_content_box_right">
          <!-- 日历 -->
          <div class="right_day">
            <div class="right_day_head">
              <div class="right_day_head_left">
                <img src="@/assets/jian@2x.png" alt=""/>
                <span>关键字或日期检索</span>
              </div>
              <div class="right_day_head_sou">
                <input type="text" v-model="keyword" placeholder="请输入"/>
                <img src="@/assets/sou2x.png" @click="keywordFc" alt="">
              </div>
            </div>
            <div class="right_day_box">
              <Calendar
                  :selectedDate="selectedDate"
                  :activeList="activeList"
                  @update:change="changeDate"
                  @update:selectedDate="onSelectedDateUpdated"/>
            </div>
          </div>
          <!-- 直播预告 -->
          <div class="right_day">
            <div class="right_day_head">
              <div class="right_day_head_left">
                <img src="@/assets/jian@2x.png" alt=""/>
                <span>直播预告</span>
              </div>
            </div>
            <div class="right_day_box1" v-if="liveobj && liveobj.live_room">
              <div class="right_day_box1_left">
                <img :src="liveobj.live_room.image" alt=""/>
              </div>
              <div class="right_day_box1_right">
                <div class="right_day_box1_right_one">
                  {{ liveobj.live_room.title }}
                </div>
                <div class="right_day_box1_right_two">
                  {{ liveobj.live_room.description }}
                </div>
                <div class="right_day_box1_right_three" @click="jumpLive(liveobj.live_room)">进入直播间</div>
              </div>
            </div>
            <div class="right_day_box1">
              暂未有任何直播内容
            </div>
          </div>
          <!-- 每日视频 -->
          <div class="right_day">
            <div class="right_day_head">
              <div class="right_day_head_left">
                <img src="@/assets/jian@2x.png" alt=""/>
                <span>每日视频</span>
              </div>
            </div>
            <div v-if="liveobj.video_today && liveobj.video_today.length > 0">
              <div class="right_day_box2">
                <div class="right_day_box2_top" @click="jump(liveobj.video_today[0].id)">
                  <div class="right_day_box1_left">
                    <img :src="liveobj.video_today[0].image" alt=""/>
                  </div>
                  <div class="right_day_box1_right">
                    <div class="right_day_box1_right_info">
                      <div class="right_day_box1_right_one">
                        {{ liveobj.video_today[0].title }}
                      </div>
                      <div class="right_day_box1_right_two">
                        {{ liveobj.video_today[0].description }}
                      </div>
                    </div>
                    <div class="right_day_box1_right_play">
                      <img src="@/assets/play.png" alt=""/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="right_day_x"></div>
              <div class="right_day_list">
                <div class="right_day_list_item" v-for="(item, index) in liveobj.video_today.slice(1, 4)" :key="index"
                     @click="jump(item.id)">
                  <div class="right_day_list_item_tps">
                    【日更】
                  </div>
                  <div class="right_day_list_item_wz">
                    {{ item.create_time.substring(0, 10) }}&nbsp;{{ item.title }}
                  </div>
                  <img class="right_day_list_item_play" src="@/assets/play1.png" alt="">
                </div>
              </div>
            </div>
          </div>
          <!-- 每周视频 -->
          <div class="right_day" >
            <div class="right_day_head">
              <div class="right_day_head_left">
                <img src="@/assets/jian@2x.png" alt=""/>
                <span>每周视频</span>
              </div>
            </div>
            <div v-if="liveobj.video_week && liveobj.video_week.length > 0">
              <div class="right_day_box2">
                <div class="right_day_box2_top" @click="jump(liveobj.video_week[0].id)">
                  <div class="right_day_box1_left">
                    <img :src="liveobj.video_week[0].description || ''" alt=""/>
                  </div>
                  <div class="right_day_box1_right">
                    <div class="right_day_box1_right_info">
                      <div class="right_day_box1_right_one">
                        {{ liveobj.video_week[0].title }}
                      </div>
                      <div class="right_day_box1_right_two">
                        {{ liveobj.video_week[0].description || '' }}
                      </div>
                    </div>
                    <div class="right_day_box1_right_play">
                      <img src="@/assets/play2.png" alt=""/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="right_day_x"></div>
              <div class="right_day_list">
                <div class="right_day_list_item" v-for="(item, index) in liveobj.video_week.slice(1, 4)" :key="index"
                     @click="jump(item.id)">
                  <div class="right_day_list_item_tps">
                    【日更】
                  </div>
                  <div class="right_day_list_item_wz">
                    {{ item.create_time.substring(0, 10) }}&nbsp;{{ item.title }}
                  </div>
                  <img class="right_day_list_item_play" src="@/assets/play1.png" alt="">
                </div>
              </div>
            </div>

          </div>
          <!-- 每月视频 -->
          <div class="right_day" >
            <div class="right_day_head">
              <div class="right_day_head_left">
                <img src="@/assets/jian@2x.png" alt=""/>
                <span>每月视频</span>
              </div>
            </div>
            <div v-if="liveobj.video_month && liveobj.video_month.length > 0">
              <div class="right_day_box2">
                <div class="right_day_box2_top" @click="jump(liveobj.video_week[0].id)">
                  <div class="right_day_box1_left">
                    <img :src="liveobj.video_week[0].description" alt=""/>
                  </div>
                  <div class="right_day_box1_right">
                    <div class="right_day_box1_right_info">
                      <div class="right_day_box1_right_one">
                        {{ liveobj.video_week[0].title }}
                      </div>
                      <div class="right_day_box1_right_two">
                        {{ liveobj.video_week[0].description }}
                      </div>
                    </div>
                    <div class="right_day_box1_right_play">
                      <img src="@/assets/play2.png" alt=""/>
                    </div>
                  </div>
                </div>
              </div>
              <div class="right_day_x"></div>
              <div class="right_day_list">
                <div class="right_day_list_item" v-for="(item, index) in liveobj.video_week.slice(1, 4)" :key="index"
                     @click="jump(item.id)">
                  <div class="right_day_list_item_tps">
                    【日更】
                  </div>
                  <div class="right_day_list_item_wz">
                    {{ item.create_time.substring(0, 10) }}&nbsp;{{ item.title }}
                  </div>
                  <img class="right_day_list_item_play" src="@/assets/play1.png" alt="">
                </div>
              </div>
            </div>

          </div>
          <!-- 小道消息 -->
          <div class="right_day">
            <div class="right_day_head">
              <div class="right_day_head_left">
                <img src="@/assets/jian@2x.png" alt=""/>
                <span>小道消息</span>
              </div>
            </div>
            <div class="right_day_list">
              <div class="right_day_list_item" v-for="(item, index) in message" :key="index" @click="jumpInfo(item.id)">
                <img class="right_day_list_item_icon" src="@/assets/wb.png" alt=""/>
                <div class="right_day_list_item_wz">
                  {{timestampToTime(item.createtime, 1)}} {{ item.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 底部信息 -->
      <div class="layout_content_footer">
        <div class="layout_content_footer_top"></div>
        <div class="layout_content_footer_bottom">
          <span>Copyright 2012-2021 {{ sysconfig.web_name }} All Rights Reserved.</span>
          <span>版权声明：{{ sysconfig.web_name }}，未经授权， 请勿转载或建立镜像，违者依法必究。</span>
          <span><a href="https://beian.mps.gov.cn/#/query/webSearch?code=12011002023051" rel="noreferrer" target="_blank">{{ sysconfig.web_beian }} </a>技术支持：鸿山网络</span>
        </div>
      </div>
    </div>
    <!-- 用户信息 -->
    <userInfo ref="userContent"/>
    <!-- 返回顶部 -->
    <el-backtop :bottom="100">
      <div style="height: 100%; width: 100%; background-color: var(--el-bg-color-overlay);
                box-shadow: var(--el-box-shadow-lighter);
                text-align: center;
                line-height: 40px;
                color: #1989fa;">UP
      </div>
    </el-backtop>
  </div>
</template>

<script setup>
import userInfo from '@/components/userInfo.vue'
import lineElement from '@/components/line.vue'
import stickView from '@/components/stick.vue'
import logView from '@/components/log.vue'
import VideoView from '@/components/video.vue'
import chartList from '@/components/chartList.vue'
import Calendar from '@/components/calendar.vue'
import textareaComponent from '@/components/textareaComponent.vue'
import {
  get_management,
  liveList,
  grapevine,
  getCategoryList,
  getTopList,
  mark,
  systemConfig
} from '@/api/index.js'
import {getCurrentDate, timestampToTime} from '@/utils/utils.js'
import {ref, computed, onMounted, nextTick} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'

const store = useStore()
const router = useRouter()
const LineElement1 = ref(null)
const LineElement2 = ref(null)
const LineElement3 = ref(null)
const userContent = ref(null)
const stickRef = ref(null)   // 论坛列表ref
const videoRef = ref(null)   // 视频列表ref
const quantization = ref(null)   // 量化信号ref
const logRef = ref(null)   // 投研日志
const threeName = ref([])
let title = ref('') // 模块标题
let menu = ref([])
let bannerList = ref([])
let childMenuId = ref('')   // 子级栏目id
let typeView = ref('')  // 判断显示内容区域组件
let selectedDate = null
let time = timestampToTime(null, 2)
let liveobj = ref({})   // 视频数据
let keyword = ref('')   // 列表搜索关键字
let timeVla = ref(Date.now() + 1000 * 60 * 10)  // 列表刷新时间
let activeList = ref([])    // 激活日期
let dateYMD = ref('')   // 当前选中日期
let message = ref([])   // 小道消息数据
let logo = require('../assets/logo1.jpg')
let sysconfig = ref({})
const user = computed(() => store.state.userInfo)   // 用户信息
const shortcuts = [     // 日期选择时间段
  {
    text: '近七天',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      return [start, end]
    }
  },
  {
    text: '近一月',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      return [start, end]
    }
  },
  {
    text: '近一年',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 360)
      return [start, end]
    }
  }
]
const keywordFc = () => {   // 日历关键字搜索
  if (typeView.value == 1 || typeView.value == 3 || typeView.value == 5) {
    stickRef.value.getKeyword(keyword.value)
  } else if (typeView.value == 4) {
    videoRef.value.getKeyword(keyword.value)
  } else if (typeView.value == 2) {
    quantization.value.getData()
  }
}

// 跳转小道消息
const jumpInfo = (id) => {
  const routeUrl = router.resolve({
    path: "/home/forumDetails",
    query: { id: id}
  });
  window.open(routeUrl.href, "_blank");
}

// 顶部轮播图跳转
const jumpBanner = (item) => {
  console.log(item)
  if (item.type === '1') {
    console.log('文本')
  } else if (item.type === '2') {
    console.log('内接')
  } else if (item.type === '3') {
    console.log('外接')
    window.open(item.content)
  }
}

// 栏目列表---树结构
const getCategoryLists = () => {
  getCategoryList()
      .then(res => {
        if (res.code === 1) {
          menu.value = res.data
          typeView.value = res.data[0].id

          if (res.data[0].children.length == 0) {
            title.value = res.data[0].name
            childMenuId.value = res.data[0].id
          } else if (res.data[0].children[0].children.length == 0) {
            title.value = `${res.data[0].name} - ${res.data[0].children[0].name}`
            childMenuId.value = res.data[0].children[0].id
          } else {
            title.value = `${res.data[0].name} - ${res.data[0].children[0].name} - ${res.data[0].children[0].children[0].name}`
            childMenuId.value = res.data[0].children[0].children[0].id
          }

          nextTick(() => {
            getMark()
            if (typeView.value == 4) {
              // getTimeTips()
              videoRef.value.getVideoList('rem')
            } else if (typeView.value == 1 || typeView.value == 3 || typeView.value == 5) {
              // getNewsTimeTips()
              stickRef.value.getNewList('rem')
            } else if (typeView.value == 2) {
              quantization.value.getData()
            }
          })
        }
      })
}
// 点击栏目
const clickItem = (id, name, menuId) => {
  childMenuId.value = id
  title.value = name
  typeView.value = menuId
  nextTick(() => {
    // 清空日期标记
    activeList.value = []
    if (typeView.value == 4) {
      getMark()
      videoRef.value.getVideoList('rem')
    } else if (typeView.value == 1 || typeView.value == 3 || typeView.value == 5) {
      getMark()
      stickRef.value.getNewList('rem')
    } else if (typeView.value == 2) {
      quantization.value.getData()
    } else if (typeView.value == 7) {
      logRef.value.getDate('rem')
    }
  })
}
// 小道消息
const getgrapevine = () => {
  grapevine()
      .then(res => {
        if (res.code === 1) {
          message.value = res.data.list
          console.log(timestampToTime(res.data.list[0].createtime, 1))
        }
      })
}
// 发布文章回调
const sendMessageResult = (event) => {
  if (event) {
    stickRef.value.getNewList('rem')
  }
}
// 重置倒计时时间
const reset = () => {
  timeVla.value = Date.now() + 1000 * 60 * 10
}
// 打开用户信息弹窗
const openUser = () => {
  userContent.value.open()
}
// 标记日期
const getMark = () => {
  let category_type = ''
  if (typeView.value == 1) {
    category_type = 'plaza'
  } else if (typeView.value == 3) {
    category_type = 'plate'
  } else if (typeView.value == 5) {
    category_type = 'news'
  } else if (typeView.value == 4) {
    category_type = 'video'
  }
  mark({
    year_month: time,
    category_type,
    category_id: childMenuId.value
  }).then(res => {
    if (res.code === 1) {
      activeList.value = []
      for (let key in res.data.days) {
        if (res.data.days[key] > 0) {
          activeList.value.push(key)
        }
      }
    }
  })
}
// 切换年月
const changeDate = (val) => {
  console.log(timestampToTime(val))
  time = timestampToTime(val, 2)
  getMark()
  // if (typeView.value == 1 || typeView.value == 3 || typeView.value == 5) {
  //     getNewsTimeTips()
  // } else if (typeView.value == 4) {
  //     getTimeTips()
  // }
}
// 切换日期
const onSelectedDateUpdated = (selectedDateVal) => {
  dateYMD.value = timestampToTime(selectedDateVal, 1)
  selectedDate = selectedDateVal
  time = timestampToTime(selectedDateVal, 2)
  nextTick(() => {
    if (typeView.value == 4) {
      videoRef.value.getVideoList('rem')
    } else if (typeView.value == 1 || typeView.value == 3 || typeView.value == 5) {
      stickRef.value.getNewList('rem')
    } else if (typeView.value == 2) {
      quantization.value.getData()
    }
  })
}
// 获取轮播图列表
const getBanner = () => {
  get_management()
      .then(res => {
        if (res.code === 1) {
          bannerList.value = res.data
        }
      })
}
// 获取视频
const getliveList = () => {
  liveList({})
      .then(res => {
        liveobj.value = res.data
      })
}
// 跳转视频详情
const jump = (id) => {
  const routeUrl = router.resolve({
    path: "/home/videoDetails",
    query: {id}
  });
  window.open(routeUrl.href, "_blank");
}
// 跳转直播详情
const jumpLive = (obj) => {
  const routeUrl = router.resolve({
    path: "/home/liveDetails",
    query: {obj: JSON.stringify(obj)}
  });
  window.open(routeUrl.href, "_blank");
}
// 获取顶部三个图表数据
const getTopLists = () => {
  getTopList({})
      .then(res => {
        if (res.code === 1) {
          threeName.value = res.data
          if (res.data.length > 0) {
            if (res.data[0]) {
              LineElement1.value.setLine(res.data[0].data)
            }
            if (res.data[1]) {
              LineElement2.value.setLine(res.data[1].data)
            }
            if (res.data[2]) {
              LineElement3.value.setLine(res.data[2].data)
            }
          }
        }
      })
}
const getSystemConfig = () => {
  systemConfig().then(res => {
    sysconfig = res.data
  })
}
onMounted(() => {
  getBanner()
  getliveList()
  getTopLists()
  getgrapevine()
  getCategoryLists()
  getSystemConfig()
})
</script>

<style>
.resize-image {
  width: 100%;
  height: 70px;
  object-fit: contain;
  display: block;
}

.infinite-list {
  height: 300px;
  padding: 0;
  margin: 0;
  list-style: none;
}

.infinite-list .infinite-list-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  background: var(--el-color-primary-light-9);
  margin: 10px;
  color: var(--el-color-primary);
}

.infinite-list .infinite-list-item + .list-item {
  margin-top: 10px;
}
</style>

<style lang="scss" scoped>
.layout {
  width: 100%;
  min-width: 975px;
  background-color: #0d2e64;
  display: flex;
  justify-content: center;
  position: relative;

  .layout_bg {
    width: 100%;
    height: 315px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-image: url('@/assets/asdf@2x.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .layout_content {
    width: 975px;
    height: auto;
    color: azure;

    .layout_content_head {
      width: 100%;
      height: 83px;
      background: rgba(0, 18, 48, 0.5);
      border-radius: 26px;
      margin-top: 25px;
      padding: 0 24px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      z-index: 2;

      .layout_content_head_day {
        font-size: 34px;
        font-family: '汉仪菱心体简';
        font-weight: 400;
        color: #FFFFFF;
      }

      .layout_content_head_banner::v-deep {
        flex-shrink: 0;
        width: 526px;
        height: 70px;
        margin: 0 17px;

        .el-carousel {
          width: 100%;

          .el-carousel__item:nth-child(2n) {
            background-color: #99a9bf;
          }

          .el-carousel__item:nth-child(2n+1) {
            background-color: #d3dce6;
          }

          .el-carousel__indicators {
            left: unset;
            transform: unset;
            right: 50%;
            transform: translate(50%, 20%);
          }

          .el-carousel__button {
            width: 8px;
            height: 8px;
            border: none;
            border-radius: 50%;
            background-color: #ffffff;
          }

          .is-active .el-carousel__button {
            background: #3f8ec8;
          }
        }
      }

      .layout_content_head_user {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        cursor: pointer;

        .user_img {
          width: 53px;
          height: 53px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 16px;

          img {
            width: 100%;
          }
        }

        .user_info {
          display: flex;
          flex-direction: column;

          span {
            font-size: 15px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #FFFFFF;
          }

          .user_info_ststus {
            display: flex;
            align-items: center;
            margin-top: 11px;

            img {
              width: 12px;
              height: 12px;
              margin-right: 5px;
            }

            .user_info_ststus_border {
              // width: 39px;
              // height: 15px;
              padding: 0 2px;
              border: 2px solid #F6CC05;
              border-radius: 3px;
              display: flex;
              align-items: center;
              justify-content: center;

              span {
                font-size: 7px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #FFD200;
              }
            }
          }
        }
      }
    }

    .layout_content_picture {
      width: 100%;
      display: flex;
      align-items: center;
      margin-top: 19px;
      position: relative;
      z-index: 2;

      .layout_content_picture_item {
        flex: 1;
        height: 271px;
        margin-right: 19px;
        background: rgba(2, 24, 63, 0.5);
        border-radius: 16px;
        padding: 16px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        &:last-child {
          margin-right: 0;
        }

        .layout_content_picture_item_head::v-deep {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .el-range-editor--small.el-input__wrapper {
            width: 160px;

            .el-range__icon {
              display: none;
            }
          }

          .layout_content_picture_item_head_title {
            display: flex;
            align-items: center;

            img {
              width: 16px;
              height: 10px;
              margin-right: 8px;
            }

            span {
              font-size: 13px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #00D5D8;
            }
          }

          .layout_content_picture_item_head_sele {
            // width: 72px;
            padding: 0 5px;
            box-sizing: border-box;
            height: 23px;
            background: rgba(13, 46, 100, 0.6);
            border-radius: 3px;
            padding: 0 11px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            cursor: pointer;
            // &:hover {
            //     .sele_menu {
            //         height: 150px;
            //     }
            // }
            // .sele_menu::-webkit-scrollbar {
            //     width: 5px;
            // }
            // .sele_menu::-webkit-scrollbar-thumb {
            //     border-radius: 8px;
            //     box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            //     background: #5D6B82;
            // }
            .sele_menu {
              position: absolute;
              top: 100%;
              left: 0;
              width: 100%;
              height: 0;
              overflow-y: scroll;
              border-radius: 5px;
              transition: .5s;
              z-index: 99;
              background-color: rgba(2, 24, 63, 0.5);

              .sele_menu_item {
                width: 100%;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 13px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;

                &:hover {
                  background-color: rgba(2, 24, 63, 0.5);
                }
              }
            }

            span {
              font-size: 13px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #FFFFFF;
              margin-right: 3px;
            }

            img {
              width: 13px;
              height: 7px;
            }
          }
        }

        .layout_content_picture_item_tu {
          width: 100%;
          height: calc(100% - 20px);
        }
      }
    }

    .layout_content_box {
      width: 100%;
      margin-top: 16px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .layout_content_box_menu {
        // width: 88px;
        width: 110px;
        height: 100vh;
        position: sticky;
        z-index: 999;
        top: 0;
        left: 0;
        border-radius: 16px 16px 0px 0px;
        background-color: rgba(2, 24, 63, 0.5);
        display: flex;
        flex-direction: column;

        .menu_logo {
          width: 100%;
          height: 73px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-bottom: 6px solid #0d2e64;

          img {
            width: 54px;
            height: 33px;
          }
        }

        .menu_top {
          width: 100%;
          height: calc(100% - 73px);
          // 294px
          display: flex;
          flex-direction: column;
          padding-bottom: 10px;
          box-sizing: border-box;
          border-bottom: 6px solid #0d2e64;

          .active {
            border-left: 3px solid #00D5D8 !important;
            background: rgba(0, 213, 216, 0.2) !important;

            span {
              color: #00D5D8 !important;
            }
          }

          .menu_top_item {
            width: 100%;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            cursor: pointer;
            z-index: 9;

            &:hover {
              .menu_top_item_list {
                display: block;
              }

              .menu_top_item_text {
                font-weight: bold !important;
              }
            }

            .menu_top_item_text {
              width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              text-align: center;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #FFFFFF;
            }

            .menu_top_item_list {
              display: none;
              width: 110px;
              position: absolute;
              right: -100%;
              top: 0;
              z-index: 999;
              background-color: rgba(2, 24, 63, 0.5);

              .menu_top_item_list_item {
                width: 100%;
                height: 45px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                z-index: 999;
                cursor: pointer;

                &:hover {
                  .menu_top_item_list_list {
                    display: block;
                  }

                  .menu_top_item_list_item_text {
                    font-weight: bold !important;
                  }
                }

                .menu_top_item_list_item_text {
                  width: 100%;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  text-align: center;
                  font-size: 14px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #FFFFFF;
                }

                .menu_top_item_list_list {
                  display: none;
                  width: 110px;
                  position: absolute;
                  right: -100%;
                  top: 0;
                  z-index: 999;
                  background-color: rgba(2, 24, 63, 0.5);

                  .menu_top_item_list_list_item {
                    width: 100%;
                    height: 45px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    z-index: 999999;
                    cursor: pointer;

                    &:hover {
                      .menu_top_item_list_list_item_text {
                        font-weight: bold !important;
                      }
                    }

                    .menu_top_item_list_list_item_text {
                      width: 100%;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      text-align: center;
                      font-size: 14px;
                      font-family: Source Han Sans CN;
                      font-weight: 400;
                      color: #FFFFFF;
                    }
                  }
                }
              }
            }
          }
        }

        .menu_bottom::-webkit-scrollbar {
          width: 5px;
        }

        .menu_bottom::-webkit-scrollbar-thumb {
          border-radius: 8px;
          box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
          background: #5D6B82;
        }

        // .menu_bottom {
        //     width: 100%;
        //     height: calc(100% - 294px);
        //     overflow-y: scroll;
        //     padding-bottom: 10px;
        //     box-sizing: border-box;
        //     .active {
        //         border-left: 3px solid #00D5D8 !important;
        //         background: rgba(0, 213, 216, 0.2) !important;
        //         span {
        //             color: #00D5D8 !important;
        //         }
        //     }
        //     .menu_top_item {
        //         width: 100%;
        //         height: 39px;
        //         display: flex;
        //         align-items: center;
        //         justify-content: center;
        //         box-sizing: border-box;
        //         margin-top: 10px;
        //         cursor: pointer;
        //         &:hover {
        //             span {
        //                 font-weight: bold !important;
        //             }
        //         }
        //         span {
        //             font-size: 14px;
        //             font-family: Source Han Sans CN;
        //             font-weight: 400;
        //             color: #FFFFFF;
        //         }
        //     }
        // }
      }

      .layout_content_box_nr {
        // flex: 1;
        width: 520px;
        height: auto;
        margin: 0 18px;
        position: relative;
        z-index: 2;
      }

      .layout_content_box_right {
        width: 313px;
        height: auto;
        display: flex;
        flex-direction: column;

        .right_day {
          width: 100%;
          height: auto;
          padding: 9px 17px;
          box-sizing: border-box;
          border-radius: 16px;
          background-color: rgba(2, 24, 63, 0.5);
          margin-bottom: 14px;

          &:last-child {
            margin-bottom: 0;
          }

          .right_day_head {
            width: 100%;
            height: 23px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .right_day_head_left {
              display: flex;
              align-items: center;

              img {
                width: 16px;
                height: 10px;
                margin-right: 8px;
              }

              span {
                line-height: 23px;
                font-size: 13px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #00D5D8;
              }
            }

            .right_day_head_sou {
              width: 138px;
              height: 23px;
              background: rgba(50, 78, 125, 0.3);
              border-radius: 3px;
              padding: 4px 9px;
              box-sizing: border-box;
              display: flex;
              align-items: center;
              justify-content: space-between;

              input::-webkit-input-placeholder {
                font-size: 10px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #8296B7;
              }

              input {
                width: 90px;
                height: 100%;
                outline: none;
                border: none;
                color: #fff;
                font-size: 10px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                background-color: rgba(0, 0, 0, 0);
              }

              img {
                flex-shrink: 0;
                width: 13px;
                height: 13px;
                cursor: pointer;
              }
            }
          }

          .right_day_box {
            width: 100%;
            margin-top: 15px;
          }

          .right_day_box1 {
            width: 100%;
            margin-top: 15px;
            display: flex;
            align-items: center;

            .right_day_box1_left {
              flex-shrink: 0;
              width: 159px;
              height: 90px;
              // border-radius: 16px;
              overflow: hidden;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 13px;

              img {
                width: 100%;
              }
            }

            .right_day_box1_right {
              flex: 1;
              height: 90px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              .right_day_box1_right_one {
                font-size: 11px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #FFCE00;
                -webkit-line-clamp: 1;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .right_day_box1_right_two {
                font-size: 9px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 21px;
                -webkit-line-clamp: 2;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .right_day_box1_right_three {
                width: 104px;
                height: 26px;
                line-height: 26px;
                text-align: center;
                background: linear-gradient(0deg, #085EAF, #2791F4);
                border-radius: 4px;
                font-size: 13px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #FFFFFF;
                cursor: pointer;
              }
            }
          }

          .right_day_box2 {
            width: 100%;
            display: flex;
            flex-direction: column;

            .right_day_box2_top {
              display: flex;
              align-items: center;
              margin-top: 15px;

              .right_day_box1_left {
                flex-shrink: 0;
                width: 84px;
                height: 46px;
                // border-radius: 16px;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 9px;

                img {
                  width: 100%;
                }
              }

              .right_day_box1_right {
                flex: 1;
                height: 46px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .right_day_box1_right_info {
                  flex: 1;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-evenly;

                  .right_day_box1_right_one {
                    font-size: 11px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #FFCE00;
                    -webkit-line-clamp: 1;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }

                  .right_day_box1_right_two {
                    font-size: 9px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #FFFFFF;
                    -webkit-line-clamp: 2;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }

                .right_day_box1_right_play {
                  width: 18px;
                  height: 18px;
                  cursor: pointer;
                  margin-left: 5px;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }
          }

          .right_day_x {
            width: 278px;
            height: 1px;
            margin: 10px 0 4px 0;
            border-bottom: 1px dashed #374969;
          }

          .right_day_list {
            width: 100%;

            .right_day_list_item {
              width: 100%;
              height: 21px;
              display: flex;
              align-items: center;
              padding: 0 5px 0 0;
              box-sizing: border-box;
              cursor: pointer;
              background-color: rgba(0, 0, 0, 0);
              transition: .3s;

              &:hover {
                background-color: rgba(255, 255, 255, 0.3) !important;

                .right_day_list_item_wz {
                  transition: .3s;
                  color: rgba(255, 255, 255, 1) !important;
                }
              }

              .right_day_list_item_tps {
                flex-shrink: 0;
                font-size: 12px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #E556FF;
              }

              .right_day_list_item_wz {
                flex: 1;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                -o-text-overflow: ellipsis;
                font-size: 13px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: rgba(132, 149, 179, 1);
              }

              .right_day_list_item_icon {
                width: 15px;
                height: 13px;
                margin-right: 9px;

                img {
                  width: 100%;
                  height: 100%;
                }
              }

              .right_day_list_item_play {
                flex-shrink: 0;
                width: 13px;
                height: 13px;
                margin-left: 5px;
              }
            }
          }
        }
      }
    }

    .layout_content_footer {
      width: 100%;
      height: 144px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      background-image: url('@/assets/footer@2x.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;

      .layout_content_footer_bottom {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-size: 9px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #8FA2C2;

          &:nth-child(2) {
            margin-right: 20px;
            margin-left: 20px;
          }
        }
      }
    }
  }
}
</style>
