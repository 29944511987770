import axios from "axios";
import store from "@/store";
import router from "@/router";
import {ElMessage} from 'element-plus'

const BASEURL = 'http://fupanai.com/'

const axiosInstance = axios.create({
    baseURL: BASEURL,
    headers: {'Content-Type': 'application/json'},
    timeout: 60000
});

// 添加请求拦截器
axiosInstance.interceptors.request.use(config => {
    const TOKEN = store.state.token ? store.state.token : null
    if (TOKEN) {
        config.headers['token'] = TOKEN
    }
    if (config.method === 'get') {
        if (store.state.token) {
            if (config.params) {
                config.params.token = store.state.token;
            }
        }
    } else {
        if (store.state.token) {
            if (config.data) {
                config.data.token = store.state.token;
            }
        }
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

// 添加响应拦截器
axiosInstance.interceptors.response.use(function (response) {
    // 登录过期
    if (response.data.code === 401) {
        store.commit('empty')
        router.push({path: '/'})
    }
    if (response.data.code !== 1) {
        ElMessage({message: response.data.msg, type: 'warning'})
    }
    if (response.data.code === 403) {
        ElMessage({message: response.data.msg, type: 'warning'})
    }
    return response.data;
}, function (error) {
    return Promise.reject(error);
});

export default {axiosInstance,BASEURL};
