<template>
    <!-- <div class="user"> -->
        <div class="user_box" v-if="isShow">
            <img class="close" src="@/assets/close@2x.png" @click="close" alt="" />
            <div class="user_box_left">
                <!-- 信息 -->
                <div class="user_box_left_info">
                    <div class="user_box_left_info_top">
                        <div class="user_box_left_info_top_tx">
                            <div class="user_box_left_info_top_tx_img">
                                <img :src="user.avatar" alt="" />
                            </div>
                            <div class="tx_name">
                                <span>{{ user.nickname }}</span>
                                <img src="@/assets/iconPark-vip3x@2x.png" alt="" />
                            </div>
                        </div>
                        <div class="user_box_left_info_top_k">
                            <img src="@/assets/zs@3x@2x.png" alt="" />
                        </div>
                    </div>
                    <div class="user_box_left_info_rem">
                        <span>关于账号权限及获取方式的说明关于账号权限及获取方式的说明关于账号权限及获取方式的说明</span>
                    </div>
                    <div class="user_box_left_info_b">
                        <div class="user_box_left_info_edit">修改个人信息</div>
                        <div class="user_box_left_info_edit" @click="loginout">退出</div>
                    </div>
                </div>
                <!-- 统计 -->
                <div class="user_box_left_num">
                    <div class="user_box_left_num_item">
                        <span class="a">{{ user?.count_list?.post_count || 0 }}</span>
                        <span class="b">发帖数</span>
                        <div class="user_box_left_num_item_x"></div>
                    </div>
                    <div class="user_box_left_num_item">
                        <span class="a">{{ user?.count_list?.total_views || 0 }}</span>
                        <span class="b">总浏览量</span>
                        <div class="user_box_left_num_item_x"></div>
                    </div>
                    <div class="user_box_left_num_item">
                        <span class="a">{{ user?.count_list?.comment_count || 0 }}</span>
                        <span class="b">评论数</span>
                        <div class="user_box_left_num_item_x"></div>
                    </div>
                    <div class="user_box_left_num_item">
                        <span class="a">{{ user?.count_list?.favorite_count || 0 }}</span>
                        <span class="b">收藏数</span>
                        <div class="user_box_left_num_item_x"></div>
                    </div>
                    <div class="user_box_left_num_item">
                        <span class="a">{{ user?.count_list?.my_favorites_count || 0 }}</span>
                        <span class="b">我的收藏</span>
                    </div>
                </div>
                <!-- 分类 -->
                <div class="user_box_left_art">
                    <div class="user_box_left_art_list">
                        <div :class="item.active ? 'user_box_left_art_list_item active': 'user_box_left_art_list_item'" v-for="(item, index) in menu" :key="index" @click="clickMenu(index, item.id)">
                            <span>{{ item.name }}</span>
                            <div class="user_box_left_art_list_item_active" v-if="item.active"></div>
                        </div>
                    </div>
                    <div class="user_box_left_art_box" :infinite-scroll-immediate="false" v-infinite-scroll="loadArticle">
                        <div class="user_box_left_art_box_item" v-for="(item, index) in from.list" :key="index">
                            <div class="art_title">
                                {{ item.title }}
                            </div>
                            <div class="art_info" v-html="item.content" v-if="type !== 'video'"></div>
                            <div class="art_info" v-else>{{ item.description }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="user_box_right">
                <!-- 联系方式/地址 -->
                <div class="user_box_right_info">
                    <div class="user_box_right_info_item">
                        <img src="@/assets/www@2x.png" alt="" />
                        <div class="user_box_right_info_item_right">
                            <span>联系方式： </span>
                            <span>{{ user?.mobile }}</span>
                        </div>
                    </div>
                    <div class="user_box_right_info_item">
                        <img src="@/assets/eee@2x.png" alt="" />
                        <div class="user_box_right_info_item_right">
                            <span>就职单位：</span>
                            <span>{{ user?.employer || '暂无' }}</span>
                        </div>
                    </div>
                    <div class="user_box_right_info_item">
                        <img src="@/assets/rrr@2x.png" alt="" />
                        <div class="user_box_right_info_item_right">
                            <span>职位：</span>
                            <span>{{ user?.position || '暂无' }}</span>
                        </div>
                    </div>
                    <div class="user_box_right_info_time">
                        <img src="@/assets/iconPark-vip3x@2x.png" alt="" />
                        <div class="user_box_right_info_item_right">
                            <span>到期时间：</span>
                            <span>{{ user?.period_of_validity || '暂无' }}</span>
                        </div>
                    </div>
                </div>
                <!-- 积分 -->
                <div class="user_box_right_integral">
                    <div class="user_box_right_integral_head">
                        <img src="@/assets/12@2x.png" alt="" />
                        <span class="c">现有积分：</span>
                        <span class="d">{{ user.score }}</span>
                    </div>
                    <div class="user_box_right_integral_list" :infinite-scroll-immediate="false" :infinite-scroll-distance="1" v-infinite-scroll="getScore">
                        <div class="user_box_right_integral_list_item" v-for="(item, index) in from.scoreList" :key="index">
                            <div class="integral_content">{{item.change_time.substring(0, 10)}}&nbsp;{{ returnText(item.change_type) }}</div>
                            <div class="integral_num">{{item.seq_flag == 1 ? '+' : '-'}}{{ item.change_num }}</div>
                        </div>
                    </div>
                    <div class="user_box_right_integral_footer">
                        <div class="user_box_right_integral_footer_btn">点击查看积分规则</div>
                    </div>
                </div>
            </div>
        </div>
    <!-- </div> -->
</template>
  
<script setup>
import { ref, reactive, defineExpose, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import {
    logout,
    getScoreList,
    getMyPlazaPostList,
    getMyFavorite,
    getMyCommentList } from '@/api/index'

const store = useStore()
const router = useRouter()
let isShow = ref(false)
let type = ref('')
let menu = ref([
    {
        name: '我的帖子',
        id: 1,
        active: true
    },
    {
        name: '视频收藏',
        id: 2,
        active: false
    },
    {
        name: '论坛收藏',
        id: 3,
        active: false
    },
    {
        name: '广场收藏',
        id: 4,
        active: false
    },
    // {
    //     name: '我的评论',
    //     id: 6,
    //     active: false
    // }
])
let from = reactive({
    page: 0,
    end: false,
    list: [],

    scorePage: 1,
    scoreEnd: false,
    scoreList: []
})
const user = computed(() => store.state.userInfo)

// 切换分类
const clickMenu = (i, id) => {
    from.list = []
    from.end = false
    from.page = 1
    menu.value.forEach((item, index) => {
        item.active = i === index;
    })
    switch(id) {
        case 1:
            type.value = 't'
            getnewsMy()
            break;
        case 2:
            console.log('视频')
            type.value = 'video'
            getfavorite(true)
            break;
        case 3:
            console.log('论坛收藏')
            type.value = 'plate_post'
            getfavorite(true)
            break;
        case 4:
            console.log('广场收藏')
            type.value = 'plaza_post'
            getfavorite(true)
            break;
        // case 6:
        //     console.log('我的评论')
        //     type.value = 'news'
        //     getCommentList()
        //     break;
    }
}

// 类型判断
const returnText = (type) => {
    switch(type) {
        case '1':
            return '注册'
        case '2':
            return '发帖'
        case '3':
            return '评论'
        case '4':
            return '转发'
        case '5':
            return '收藏'
        case '6':
            return '加精'
        case '7':
            return '置顶'
        case '8':
            return '被收藏'
        case '9':
            return '被转发'
        case '10':
            return '下载附件'
    }
}

// 获取收藏列表
const getfavorite = (reset) => {
    if (reset) {
        from.page = 1
        from.list = []
        from.end = false
    }
    if (from.end) return
    getMyFavorite({
        table_name: type.value,
        page: from.page,
        limit: 5
    }).then(res => {
        if (res.code === 1) {
            if (type.value == 'video') {
                from.list.push(...res.data.video_list)
            } else if (type.value == 'plate_post') {
                from.list.push(...res.data.plate_post_list)
            } else if (type.value == 'plaza_post') {
                from.list.push(...res.data.plaza_post_list)
            }
            if (res.data.total === from.list.length) {
                from.end = true
            }
        }
    })
}

// 获取我的评论列表
const getCommentList = () => {
    getMyCommentList({
        page: from.page,
        limit: 10,
        type: 'news'
    })
}
 
// 退出
const loginout = () => {
    logout()
        .then(res => {
            store.commit('empty')
            router.push('/')
        })
}

// 获取积分
const getScore = () => {
    if (from.scoreEnd) return
    getScoreList({
        limit: 10,
        page: from.scorePage
    }).then(res => {
        if (res.code === 1) {
            if (res.data.score_list.length > 0) {
                from.scorePage += 1
                from.scoreList = [...from.scoreList, ...res.data.score_list]
            } else {
                from.scoreEnd = true
            }
        }
    })
}

const open = () => {
    menu.value.forEach((item, index) => {
        item.active = index === 0;
    })
    store.dispatch('getUserInfo')   // 更新个人信息
    getScore()  // 获取积分列表
    getnewsMy()   // 获取我发布的文章
    document.querySelector('body').classList.add('no-scroll');
    isShow.value = true
}

const close = () => {
    isShow.value = false
    from.list = []
    from.scoreList = []
    from.end = false
    from.scoreEnd = false
    from.page = 1
    from.scorePage = 1
    document.querySelector('body').classList.remove('no-scroll');
}

// 触底加载
const loadArticle = () => {
    from.page += 1
    if (type.value == 't') {
        getnewsMy()
    } else {
        getfavorite()
    }
}

// 获取我发布的文章
const getnewsMy = async () => {
    if (!from.end) {
        let res = await getMyPlazaPostList({ limit: 10, page: from.page })
        if (res.code === 1) {
            // from.page = from.page += 1
            from.list = [...from.list, ...res.data.list]
            if (res.data.list === 0) {
                from.end = true
            }
        }
    }
}

// 向父组件暴露方法
defineExpose({
    open, close
})

onMounted(() => {
    // getnewsMy()
})
</script>

<style lang="scss" scoped>
// .user {
//     width: 100vw;
//     height: 100vh;
//     position: fixed;
//     top: 0;
//     left: 0;
//     z-index: 9;
//     display: flex;
//     align-items: center;
//     justify-content: center;
    .user_box {
        position: fixed;
        padding: 22px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-sizing: border-box;
        border-radius: 16px;
        background-color: rgba(6, 23, 50, 1);
        z-index: 9;
        // position: relative;
        display: flex;
        align-items: flex-start;
        .user_box_left {
            width: 435px;
            margin-right: 9px;
            .user_box_left_info {
                width: 100%;
                padding: 20px;
                height: 288px;
                background: #0D2E64;
                border-radius: 6px;
                box-sizing: border-box;
                .user_box_left_info_top {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .user_box_left_info_top_tx {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        .user_box_left_info_top_tx_img {
                            width: 84px;
                            height: 84px;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;
                            img {
                                width: 84px;
                                height: 84px;
                            }
                        }
                        .tx_name {
                            display: flex;
                            align-items: center;
                            margin-top: 19px;
                            span {
                                font-size: 13px;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                color: #FFFFFF;
                            }
                            img {
                                width: 13px;
                                height: 13px;
                                margin-left: 8px;
                            }
                        }
                    }
                    .user_box_left_info_top_k {
                        img {
                            width: 233px;
                            height: 132px;
                        }
                    }
                }
                .user_box_left_info_rem {
                    width: 100%;
                    margin-top: 26px;
                    span {
                        font-size: 10px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #FFFFFF;
                    }
                }
                .user_box_left_info_b {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    .user_box_left_info_edit {
                        width: 81px;
                        height: 28px;
                        line-height: 28px;
                        text-align: center;
                        background: linear-gradient(0deg, #085EAF, #2791F4);
                        border-radius: 8px;
                        font-size: 10px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #FFFFFF;
                        cursor: pointer;
                        margin-top: 17px;
                        margin-right: 20px;
                    }
                }
            }
            .user_box_left_num {
                width: 100%;
                height: 84px;
                background: #0D2E64;
                border-radius: 6px;
                margin-top: 9px;
                display: flex;
                align-items: center;
                .user_box_left_num_item {
                    flex: 1;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    position: relative;
                    .user_box_left_num_item_x {
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translate(0, -50%);
                        width: 1px;
                        height: 21px;
                        background: #fff;
                    }
                    .a {
                        font-size: 21px;
                        font-family: Source Han Sans CN;
                        font-weight: bold;
                        color: #FFFFFF;
                    }
                    .b {
                        font-size: 10px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #FFFFFF;
                        margin-top: 5px;
                    }
                }
            }
            .user_box_left_art {
                width: 100%;
                height: 256px;
                padding: 20px 20px;
                box-sizing: border-box;
                background: #0D2E64;
                border-radius: 6px;
                margin-top: 9px;
                .user_box_left_art_list {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    .active {
                        span {
                            color: #fff !important;
                        }
                    }
                    .user_box_left_art_list_item {
                        flex: 1;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        span {
                            font-size: 11px;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            color: rgba(255, 255, 255, 0.3);
                        }
                        .user_box_left_art_list_item_active {
                            position: absolute;
                            bottom: -5px;
                            left: 50%;
                            width: 31px;
                            height: 2px;
                            background: #48A6FF;
                            border-radius: 1px;
                            transform: translate(-50%, 0);
                        }
                    }
                }
                .user_box_left_art_box::-webkit-scrollbar {
                    width: 5px;
                }
                .user_box_left_art_box::-webkit-scrollbar-thumb {
                    border-radius: 8px;
                    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                    background: #5D6B82;
                }
                .user_box_left_art_box {
                    width: 100%;
                    height: calc(100% - 30px);
                    margin-top: 18px;
                    overflow-y: scroll;
                    .user_box_left_art_box_item {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        cursor: pointer;
                        padding-bottom: 10px;
                        margin-bottom: 10px;
                        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
                        &:last-child {
                            margin-bottom: 0 !important;
                            border: none;
                        }
                        .art_title {
                            font-size: 11px;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            color: #FFFFFF;
                            margin-bottom: 5px;
                        }
                        .art_info::v-deep {
                            font-size: 11px;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            color: #779FE0;
                            overflow:hidden;
                            text-overflow: ellipsis;
                            -webkit-line-clamp: 2;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            img {
                                width: 100%;
                            }
                        }
                        .art_time {
                            display: flex;
                            align-items: center;
                            margin-top: 5px;
                            span {
                                font-size: 10px;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                color: #3661A6;
                                &:first-child {
                                    margin-right: 25px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .user_box_right {
            width: 245px;
            .user_box_right_info {
                width: 100%;
                padding: 20px;
                height: 288px;
                box-sizing: border-box;
                background: #0D2E64;
                border-radius: 6px;
                .user_box_right_info_time {
                    width: 100%;
                    padding: 12px;
                    height: 55px;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    background: linear-gradient(0deg, #085EAF, #2791F4);
                    border-radius: 6px;
                    margin-bottom: 9px;
                    img {
                        flex-shrink: 0;
                        width: 13px;
                        height: 15px;
                        margin-right: 13px;
                    }
                    .user_box_right_info_item_right {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        span {
                            font-size: 10px;
                            font-family: Source Han Sans CN;
                            font-weight: 500;
                            color: #FFFFFF;
                            &:last-child {
                                margin-top: 7px;
                            }
                        }
                    }
                }
                .user_box_right_info_item {
                    width: 100%;
                    height: 55px;
                    padding: 12px;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    background: rgba(32, 86, 137, 0.5);
                    border-radius: 6px;
                    margin-bottom: 9px;
                    img {
                        flex-shrink: 0;
                        width: 13px;
                        height: 15px;
                        margin-right: 13px;
                    }
                    .user_box_right_info_item_right {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        span {
                            font-size: 10px;
                            font-family: Source Han Sans CN;
                            font-weight: 500;
                            color: #FFFFFF;
                            &:last-child {
                                margin-top: 7px;
                            }
                        }
                    }
                }
            }
            .user_box_right_integral {
                width: 245px;
                height: 350px;
                padding: 20px;
                background: #0D2E64;
                border-radius: 6px;
                box-sizing: border-box;
                margin-top: 9px;
                .user_box_right_integral_head {
                    width: 100%;
                    height: 30px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .c {
                        font-size: 13px;
                        font-family: Source Han Sans CN;
                        font-weight: 500;
                        color: #FFFFFF;
                    }
                    .d {
                        font-size: 13px;
                        font-family: Source Han Sans CN;
                        font-weight: 500;
                        color: rgba(243, 172, 19, 1);
                    }
                    img {
                        width: 100%;
                        height: 6px;
                        position: absolute;
                        top: 13px;
                        left: 0;
                    }
                }
                .user_box_right_integral_list::-webkit-scrollbar {
                    width: 5px;
                }
                .user_box_right_integral_list::-webkit-scrollbar-thumb {
                    border-radius: 8px;
                    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                    background: #5D6B82;
                }
                .user_box_right_integral_list {
                    width: 100%;
                    height: calc(100% - 58px - 46px);
                    overflow: auto;
                    margin-top: 23px;
                    .user_box_right_integral_list_item {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 23px;
                        &:first-child {
                            margin-top: 0 !important;
                        }
                        .integral_content {
                            flex: 1;
                            overflow:hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            -o-text-overflow:ellipsis;
                            font-size: 9px;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            color: #FFFFFF;
                        }
                        .integral_num {
                            flex-shrink: 0;
                            margin-left: 20px;
                            font-size: 9px;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            color: rgba(41, 232, 15, 1);
                        }
                    }
                }
                .user_box_right_integral_footer {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 28px;
                    margin-top: 23px;
                    .user_box_right_integral_footer_btn {
                        width: 112px;
                        height: 28px;
                        line-height: 28px;
                        text-align: center;
                        background: linear-gradient(0deg, #085EAF, #2791F4);
                        border-radius: 6px;
                        font-size: 10px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #FFFFFF;
                        cursor: pointer;
                    }
                }
            }
        }
        .close {
            position: absolute;
            right: -10px;
            top: -10px;
            width: 31px;
            height: 31px;
            cursor: pointer;
        }
    }
// }
</style>