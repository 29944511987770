<template>
  <div class="chatIcons">
    <!-- 刷新 -->
    <div class="chatIcon_head">
      <div class="chatIcon_head_left">
        <img src="@/assets/jian@2x.png" alt=""/>
        <span>{{ props.title }}</span>
      </div>
      <div class="chatIcon_head_right">
        <span><el-countdown :value="props.timeVal" @finish="finish"/>后自动更新</span>
        <div class="chatIcon_head_right_btn" @click="getNewList('rem')">
          <img src="@/assets/update@2x.png" alt=""/>
          <span>手动更新</span>
        </div>
      </div>
    </div>
    <!-- 操作 -->
    <div class="chatIcon_operate">
      <div class="layout_content_picture_item_head_sele">
        <span>{{ from.sortName }}</span>
        <img src="@/assets/dwon@2x.png" alt=""/>
        <div class="sele_menu">
          <div class="sele_menu_item" v-for="(item, index) in sort" :key="index" @click="clickSort(item)">{{
              item.name
            }}
          </div>
        </div>
      </div>
      <div class="chatIcon_operate_cate">
        <div :class="item.active ? 'chatIcon_operate_cate_item active' : 'chatIcon_operate_cate_item'"
             @click="clickDate(index, item.id)" v-for="(item, index) in dateType" :key="index">{{ item.name }}
        </div>
      </div>
      <div class="chatIcon_operate_w" @click="openProp">
        <img src="@/assets/wen.png" alt="" style="width: 20px;height: 20px">
      </div>
    </div>
    <div class="chatIcon_list">
      <div class="chatIcon_list_item" v-for="(item, index) in from.list" :key="index" @click="jumpDetails(item)">
        <div class="chatIcon_list_item_head">
          <img :src="item.avatar" alt=""/>
          <span class="head_name">{{ item.username }}</span>
          <span class="head_time">{{ item.create_time }}</span>
        </div>
        <div class="chatIcon_list_item_info" v-html="item.post_content"></div>
        <div class="chatIcon_list_item_pj" @click.stop>
          <div class="chatIcon_list_item_pj_item" v-for="(comment, i) in item.commentList" :key="i">
            <!-- 一级评论回复弹窗 -->
            <div class="chatIcon_list_item_pj_item_pl" @click.stop v-if="comment.active">
              <div class="item_pl_user">
                <span>@{{ comment.user.username }}</span>
                <span @click.stop="closeComment(comment)">x</span>
              </div>
              <div class="item_pl_content">
                <textarea v-model="comment.text" placeholder="请输入内容"></textarea>
              </div>
              <div class="item_pl_caozuo">
                <el-popover
                    placement="bottom"
                    title="请选择表情"
                    :width="300"
                    @before-enter="beforeIconChild(index, i)"
                    trigger="click">
                  <template #reference>
                    <img src="@/assets/icon.png" alt=""/>
                  </template>
                  <chatIcon @changeText="getIconChild"/>
                </el-popover>
                <imageContent :content="comment.text" :obj="comment" :type="'from.list['+index+'].commentList'"
                              @result="imageContentResult"></imageContent>
                <div class="item_pl_caozuo_btn" @click="sendChildComment(comment, '', comment.id)">发送</div>
              </div>
            </div>
            <div class="chatIcon_list_item_pj_item_top">
              <div class="pj_item_top_user">
                <img :src="comment.user.avatar" alt=""/>
                <span>{{ comment.user.username }}的评论</span>
              </div>
              <div class="pj_item_top_time">
                <div class="pj_item_top_time_a" @click.stop="handleMouseEnter(comment)">
                  <img src="@/assets/pl@2x.png" alt=""/>
                  <span>评论</span>
                </div>
                <div class="pj_item_top_time_b">{{ comment?.createtime }}</div>
              </div>
            </div>
            <div class="chatIcon_list_item_pj_item_bottom">
              <div v-html="handleMktoHtml(comment.content)" style="font-size: 14px" class="comment-box"
                   @click="handleClick"></div>
              <span></span>
              <!-- <span>跟多</span> -->
            </div>
            <!-- 子级评论列表 -->
            <div class="chatIcon_list_item_pj_item_list" v-if="comment.childernComment.length > 0">
              <div class="chatIcon_list_item_pj_item_list_item" v-for="(child, s) in comment.childernComment" :key="s">
                <!-- 二级评论回复弹窗 -->
                <div class="chatIcon_list_item_pj_item_pl" @click.stop v-if="child.active">
                  <div class="item_pl_user">
                    <span>@{{ child.user.username }}</span>
                    <span @click.stop="closeComment(child)">x</span>
                  </div>
                  <div class="item_pl_content">
                    <textarea v-model="child.text" placeholder="请输入内容"></textarea>
                  </div>
                  <div class="item_pl_caozuo">
                    <el-popover
                        placement="bottom"
                        title="请选择表情"
                        :width="300"
                        @before-enter="beforeIconChild(index, i)"
                        trigger="click">
                      <template #reference>
                        <img src="@/assets/icon.png" alt=""/>
                      </template>
                      <chatIcon @changeText="getIconChild"/>
                    </el-popover>
                    <imageContent :content="child.text" :obj="child"
                                  :type="'from.list['+index+'].commentList['+i+'].childernComment'"
                                  @result="imageContentResult"></imageContent>
                    <div class="item_pl_caozuo_btn" @click="sendChildComment(child, comment, comment.id)">发送</div>
                  </div>
                </div>
                <div class="chatIcon_list_item_pj_item_top">
                  <div class="pj_item_top_user">
                    <img :src="child?.user?.avatar" alt=""/>
                    <span>{{ child?.user?.username }}的评论</span>
                  </div>
                  <div class="pj_item_top_time">
                    <div class="pj_item_top_time_a" @click.stop="handleMouseEnter(child)">
                      <img src="@/assets/pl@2x.png" alt=""/>
                      <span>评论</span>
                    </div>
                    <div class="pj_item_top_time_b">{{ child?.createtime }}</div>
                  </div>
                </div>
                <div class="chatIcon_list_item_pj_item_bottom">
                  <div v-html="handleMktoHtml(child?.content)" style="font-size: 14px" class="comment-box"></div>
                </div>
                <div class="chatIcon_list_item_pj_more"
                     v-if="comment.isShow && (s == comment.childernComment.length - 1)"
                     @click="getCommentList(child.aid, comment.id, comment.page, index, i, comment, child.top_pid ? child.top_pid : child.id )">
                  展开查看更多 >
                </div>
              </div>
            </div>
            <!-- 加载更多评论 -->
            <div class="chatIcon_list_item_pj_more"
                 v-if="comment.childernComment.length == 0 && comment.is_children == 1"
                 @click="getCommentList(comment.aid, comment.id, comment.page, index, i, comment, comment.top_pid ? comment.top_pid : comment.id)">
              展开查看更多 >
            </div>
          </div>
        </div>
        <div class="chatIcon_list_item_footer" @click.stop>
          <div class="chatIcon_list_item_footer_left">
            <div class="chatIcon_list_item_footer_left_item" @click.stop="Like(item.id, item.is_mylike, item)">
              <img :src="item.is_mylike == 1 ? require('@/assets/dianz.png') : require('@/assets/zz.png')" alt=""/>
              <span>{{ item.post_like }}</span>
            </div>
            <div class="chatIcon_list_item_footer_left_item" @click.stop="collect(item.id, item.is_myfavorite, item)">
              <img :src="item.is_myfavorite == 1 ? require('@/assets/shouc.png') : require('@/assets/sc.png')" alt=""/>
              <span>收藏</span>
            </div>
            <el-popover
                :width="150"
                trigger="hover">
              <template #reference>
                <div class="chatIcon_list_item_footer_left_item">
                  <img src="@/assets/zf.png" alt=""/>
                  <span>转发</span>
                </div>
              </template>
              <div style="width: 100%; display: flex; align-items: center;">
                <div
                    style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;">
                  <img style="width: 30px; height: 30px;" src="@/assets/wx.png" alt=""/>
                  <span style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;">微信</span>
                </div>
                <div @click="shareMicroBlog"
                     style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;">
                  <img style="width: 30px; height: 30px;" src="@/assets/wb.png" alt=""/>
                  <span style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;">微博</span>
                </div>
                <div @click="shareQQ"
                     style="cursor: pointer; flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;">
                  <img style="width: 30px; height: 30px;" src="@/assets/QQ@2x.png" alt=""/>
                  <span style="margin-top: 2px; font-size: 13px; color: #666; font-weight: 400;">QQ</span>
                </div>
              </div>
            </el-popover>
            <div class="chatIcon_list_item_footer_left_item">
              <img src="@/assets/pl.png" alt=""/>
              <span>评论</span>
            </div>
          </div>
          <div class="chatIcon_list_item_footer_right">
            <div class="inputText">
              <input type="text" v-model="item.text" placeholder="分享你的评论"/>
            </div>
            <div class="caozuo">
              <el-popover
                  placement="bottom"
                  title="请选择表情"
                  :width="300"
                  @before-enter="beforeIcon(index)"
                  trigger="click">
                <template #reference>
                  <img src="@/assets/icon.png" alt=""/>
                </template>
                <chatIcon @changeText="getIcon"/>
              </el-popover>
              <imageContent :content="item.text" :obj="item" type="from.list"
                            @result="imageContentResult"></imageContent>

              <div class="caozuo_btn" @click="sendCommentVal(item.id, item.text, item)">发送</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loading v-if="loading"/>
    <notFind v-if="!loading && from.list.length === 0"/>
    <div class="info" v-if="!loading && from.list.length > 0 && noMore">
      <el-text class="mx-1" type="info">没有更多了~</el-text>
    </div>
  </div>
  <el-dialog v-model="dialogFormVisible" title="">
    <textareaComponent :typeView="1" :channel="1" @result="getNewList('rem')"/>
  </el-dialog>
  <vue-easy-lightbox
      :visible="visible"
      :imgs="images"
      :index="0"
      @hide="handleHide"
  ></vue-easy-lightbox>
</template>

<script setup>
import {defineEmits, defineExpose, defineProps, onMounted, onUnmounted, reactive, ref} from 'vue'
import {addComment, addFavorite, addLive, delFavorite, delLive, getComment, plaza_post_index} from '@/api/index'
import Loading from '@/components/Loading.vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import {toBase64} from '@/utils/utils'
import {marked} from 'marked';

const store = useStore()
const router = useRouter()
const props = defineProps({
  title: String,  // 栏目标题
  channel: Number,     // 栏目id
  timeVal: Number,   // 刷新时间
  create_time: String,     // 创建时间
  typeView: String | Number    // 1 首页广场   5 新闻资讯    3 债市论坛
})
const emit = defineEmits(['finish'])
let sort = ref([
  {name: '默认排序', id: 'desc'},
  {name: '降序', id: 'desc'},
  {name: '升序', id: 'asc'}
])
let dateType = ref([
  {name: '今日', id: 1, active: true},
  {name: '七日', id: 2, active: false},
  {name: '月', id: 3, active: false},
  {name: '年', id: 4, active: false}
])
let loading = ref(true)
let visible = ref(false)
let images = ref([])

let from = reactive({
  page: 1,
  total: 0,
  keyword: '',
  sortName: '降序',
  sortId: 'desc',
  dateType: 1,
  index: 0,
  indexChild: 0,
  list: []
})
let noMore = ref(false)
let dialogFormVisible = ref(false)
// 倒计时结束
const finish = () => {
  getNewList('rem')
  emit('finish')
}
// 打开发布框
const openProp = () => {
  dialogFormVisible.value = true
}
const echoerror = (err) => {
  console.log(err)
}
// 点击评论
const handleMouseEnter = (comment) => {
  comment.active = true
}
// 关闭评论
const closeComment = (comment) => {
  comment.active = false
}
// 获取评论
const getCommentList = (aid, pid, page, f, z, comment, top_pid) => {
  let type = ''
  if (props.typeView == 1) {
    type = 'plaza'
  } else if (props.typeView == 3) {
    type = 'bbs'
  } else if (props.typeView == 5) {
    type = 'news'
  }
  getComment({type, aid, pid, orderway: 'desc', page, limit: 5, top_pid})
      .then(res => {
        if (res.code === 1) {
          comment.page += 1
          res.data.list.forEach(item => {
            item.isOpen = false
            item.text = ''
          })
          if (!from.list[f].commentList[z].childernComment) {
            from.list[f].commentList[z].childernComment.push(...res.data.list)
          } else {
            res.data.list.forEach((child, ss) => {
              from.list[f].commentList[z].childernComment.forEach(item => {
                if (child.id == item.id) {
                  res.data.list.splice(ss, 1)
                }
              })
            })
            from.list[f].commentList[z].childernComment.push(...res.data.list)
          }
          if (from.list[f].commentList[z].childernComment.length === res.data.total) {
            from.list[f].commentList[z].isShow = false
          } else {
            from.list[f].commentList[z].isShow = true
          }
        }
      })
}
// 跳转详情
const jumpDetails = (item) => {
  const newObj = (({category_type, comment_type, id, table_name}) => ({
    category_type,
    comment_type,
    id,
    table_name
  }))(item);
  const content = toBase64(JSON.stringify(newObj))
  if (props.typeView == 1 || props.typeView == 3) {
    const routeUrl = router.resolve({
      path: "/home/forumDetails",
      query: {id: item.id, type: props.typeView, content: content}
    });
    window.open(routeUrl.href, "_blank");
  } else if (props.typeView == 5) {
    const routeUrl = router.resolve({
      path: "/home/newDetails",
      query: {id: item.id, type: props.typeView, content: content}
    });
    window.open(routeUrl.href, "_blank");
  }
}
// 父级评论打开图标
const beforeIcon = (index) => {
  from.index = index
}
// 子级评论打开图标
const beforeIconChild = (a, b) => {
  from.index = a
  from.indexChild = b
}
// 子级评论选择表情
const getIconChild = (e) => {
  from.list[from.index].commentList[from.indexChild].text += e
}
// 点赞
const Like = (id, status, item) => {
  let table_name = ''
  if (props.typeView == 1) {
    table_name = item.table_name
  } else if (props.typeView == 3) {
    table_name = item.table_name
  } else if (props.typeView == 5) {
    table_name = item.table_name
  }
  if (status == 1) {
    delLive({table_name, object_id: id})
        .then(res => {
          if (res.code === 1) {
            item.is_mylike = 0
            item.post_like -= 1
          }
        })
  } else {
    addLive({table_name, object_id: id})
        .then(res => {
          if (res.code === 1) {
            item.is_mylike = 1
            item.post_like += 1
          }
        })
  }
}
// 父级评论选择表情
const getIcon = (e) => {
  from.list[from.index].text += e
}
// 收藏
const collect = (id, status, item) => {
  let table_name = ''
  if (props.typeView == 1) {
    table_name = item.table_name
  } else if (props.typeView == 3) {
    table_name = item.table_name
  } else if (props.typeView == 5) {
    table_name = item.table_name
  }
  if (status == 1) {
    delFavorite({table_name, object_id: id})
        .then(res => {
          if (res.code === 1) {
            item.is_myfavorite = 0
          }
        })
  } else {
    addFavorite({table_name, object_id: id})
        .then(res => {
          if (res.code === 1) {
            item.is_myfavorite = 1
          }
        })
  }
}
// 子级发送评论
const sendChildComment = (obj, comment, top_pid) => {
  let type = ''
  if (props.typeView == 1) {
    type = 'plaza'
  } else if (props.typeView == 3) {
    type = 'bbs'
  } else if (props.typeView == 5) {
    type = 'news'
  }

  addComment({type, aid: obj.aid, pid: obj.id, content: obj.text, top_pid})
      .then(res => {
        if (res.code === 1) {
          obj.active = false
          obj.text = ''
          obj.isShow = true
          res.data.isOpen = false
          res.data.text = ''
          if (comment) {
            comment.childernComment.push(res.data)
          } else {
            obj.childernComment.push(res.data)
          }
        }
      })
}
// 父级发送评论
const sendCommentVal = (id, text, item) => {
  let type = ''
  if (props.typeView == 1) {
    type = item.comment_type
  } else if (props.typeView == 3) {
    type = 'bbs'
  } else if (props.typeView == 5) {
    type = 'news'
  }
  addComment({type, aid: id, pid: 0, content: text, top_pid: 0})
      .then(res => {
        if (res.code === 1) {
          item.text = ''
          res.data.isOpen = false
          res.data.text = false
          res.data.page = 1
          res.data.childernComment = []
          res.data.user.username = res.data.user.nickname
          console.log(res.data.user.username)
          item.commentList.push(res.data)
        }
      })
}
// 选择排序方式
const clickSort = (row) => {
  from.sortId = row.id
  from.sortName = row.name
  getNewList('rem')
}
// 选择日期
const clickDate = (i, id) => {
  from.dateType = id
  dateType.value.forEach((item, index) => {
    item.active = index === i
  })
  getNewList('rem')
}
// 关键字搜索
const getKeyword = (val) => {
  from.keyword = val
  getNewList('rem')
}

// 获取列表数据
const getNewList = (type) => {
  if (type === 'rem') {
    from.list = []
    from.page = 1
    noMore.value = false
  }
  if (noMore.value) return
  loading.value = true
  let category_type = ''
  if (props.typeView == 1) {
    category_type = 'plaza'
  } else if (props.typeView == 3) {
    category_type = 'plate'
  } else if (props.typeView == 5) {
    category_type = 'news'
  }
  plaza_post_index({
    category_id: props.channel,
    page: from.page,
    limit: 20,
    type: props.create_time ? '' : from.dateType,
    orderway: from.sortId,
    keyword: from.keyword,
    time: props.create_time ? props.create_time : 0,
    category_type
  }).then(res => {
    if (res.code === 1) {
      from.page += 1
      from.total = res.data.total
      // 首页广场
      if (props.typeView == 1) {
        console.log(res.data)
        if (res.data.platePostList.length > 0) {
          res.data.platePostList.forEach(item => {
            item.text = ''
            if (item.commentList.length > 0) {
              item.commentList.forEach(row => {
                row.isOpen = false
                row.text = ''
                row.page = 1
                row.childernComment = []
              })
            }
          })
          from.list = [...from.list, ...res.data.platePostList]
          if ((from.list && from.list.length === res.data.total) || (res.data.plazaList && res.data.plazaList.length === 0)) {
            noMore.value = true
          }
        }

        // 新闻资讯
      } else if (props.typeView == 5) {
        res.data.newsList.forEach(item => {
          item.text = ''
          if (item.commentList.length > 0) {
            item.commentList.forEach(row => {
              row.isOpen = false
              row.text = ''
              row.page = 1
              row.childernComment = []
            })
          }
        })
        from.list = [...from.list, ...res.data.newsList]
        if ((from.list.length === res.data.total) || res.data.newsList.length === 0) {
          noMore.value = true
        }
        // 债市论坛
      } else if (props.typeView == 3) {
        res.data.plateList.forEach(item => {
          item.text = ''
          if (item.commentList.length > 0) {
            item.commentList.forEach(row => {
              row.isOpen = false
              row.text = ''
              row.page = 1
              row.childernComment = []
            })
          }
        })
        from.list = [...from.list, ...res.data.plateList]
        if ((from.list.length === res.data.total) || res.data.plateList.length === 0) {
          noMore.value = true
        }
      }
    }
  }).finally(() => {
    loading.value = false
  })
  dialogFormVisible = false
}

// 分享到QQ
const shareQQ = () => {
  console.log('share')
  var url = window.location.href;
  var encodedUrl = encodeURIComponent(url);
  var shareUrl = 'http://connect.qq.com/widget/shareqq/index.html?url=' + encodedUrl;
  window.open(shareUrl);
}

// 分享到微博
const shareMicroBlog = () => {
  let message = String(location.href)
  var url = 'https://service.weibo.com/share/share.php?url=' + encodeURIComponent(message) + '&appkey=MrShi&title=测试标题&language=zh_cn'
  window.open(url)
  // count=表示是否显示当前页面被分享数量(1显示)(可选，允许为空)
  // &url=将页面地址转成短域名，并显示在内容文字后面。(可选，允许为空)
  // &appkey=用于发布微博的来源显示，为空则分享的内容来源会显示来自互联网。(可选，允许为空)
  // &title=分享时所示的文字内容，为空则自动抓取分享页面的title值(可选，允许为空)
  // &pic=自定义图片地址，作为微博配图(可选，允许为空)
  // &ralateUid=转发时会@相关的微博账号(可选，允许为空)
  // &language=语言设置(zh_cn|zh_tw)(可选)
}

const scrollFn = (e) => {
  //可视区域
  let clientHeight = document.documentElement.clientHeight
  // 滚动文档高度
  let scrollHeight = document.body.scrollHeight
  // 已滚动的高度
  let scrollTop = parseInt(document.documentElement.scrollTop)
  if ((scrollTop + clientHeight) === scrollHeight) {
    console.log('触底了')
    if (!noMore.value) {
      console.log('加载了')
      getNewList()
    }
  }
}

const imageContentResult = (e) => {
  let str = e.type;
  let obj = eval(str);
  for (let i = 0; i < obj.length; i++) {
    if (obj[i].category_type === e.item.category_type && obj[i].id === e.item.id) {
      obj[i].text = e.content
    }
  }
  console.log(e, str)
}
const handleMktoHtml = (e) => {
  return marked(e)
}
const handleHide = () => {
  images.value = []
  visible.value = false
}
const handleClick = (e) => {
  if (e.target.tagName === 'IMG') {
      // 获取 img 标签的 src 属性
    images.value = [e.target.src]
    visible.value = true
    console.log(e.target.src,images.value)
  }
}
defineExpose({getNewList, getKeyword})

onMounted(() => {
  window.addEventListener("scroll", scrollFn)
})

onUnmounted(() => {
  window.removeEventListener("scroll", scrollFn);
})
</script>
<style>
.comment-box img {
  width: 100px;
}
</style>
<style lang="scss" scoped>
.chatIcons {
  width: 100%;
  background: #02183F;
  border-radius: 16px;
  margin-top: 18px;
  padding: 18px;
  box-sizing: border-box;

  .info {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }

  .chatIcon_head {
    width: 100%;
    height: 30px;
    position: sticky;
    z-index: 9;
    background: #02183F;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .chatIcon_head_left {
      display: flex;
      align-items: center;

      img {
        width: 16px;
        height: 10px;
        margin-right: 6px;
      }

      span {
        font-size: 13px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #00D5D8;
      }
    }

    .chatIcon_head_right {
      display: flex;
      align-items: center;

      span::v-deep {
        font-size: 13px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #FFFFFF;
        display: flex;
        align-items: center;

        .el-statistic {
          .el-statistic__content {
            display: flex;
            align-items: center;

            .el-statistic__number {
              font-size: 13px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #FFFFFF;
            }
          }
        }
      }

      .chatIcon_head_right_btn {
        // width: 78px;
        height: 26px;
        padding: 8px;
        box-sizing: border-box;
        background: linear-gradient(0deg, #085EAF, #2791F4);
        border-radius: 4px;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: 16px;

        img {
          width: 11px;
          height: 11px;
          margin-right: 6px;
        }

        span {
          font-size: 11px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #FFFFFF;
        }
      }
    }
  }

  .chatIcon_operate {
    width: 100%;
    height: 30px;
    margin: 5px 0 17px 0;
    display: flex;
    align-items: center;
    position: sticky;
    background: #02183F;
    top: 30px;
    z-index: 9;

    .chatIcon_operate_w {
      width: 25px;
      height: 25px;
      font-size: 16px;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #0B3D6C;
      border-radius: 5px;
      cursor: pointer;
    }

    .chatIcon_operate_cate {
      flex: 1;
      margin-left: 30px;
      display: flex;
      align-items: center;

      .active {
        background: linear-gradient(0deg, #085EAF, #2791F4) !important;
      }

      .chatIcon_operate_cate_item {
        width: 36px;
        height: 23px;
        line-height: 23px;
        text-align: center;
        background: #0B3D6C;
        border-radius: 3px;
        font-size: 10px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        margin-right: 9px;
        cursor: pointer;
      }
    }

    .layout_content_picture_item_head_sele {
      flex-shrink: 0;
      width: 98px;
      height: 23px;
      background: rgba(13, 46, 100, 0.6);
      border-radius: 3px;
      border: 1px solid rgba(119, 198, 253, 0.3);
      padding: 0 11px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      cursor: pointer;

      &:hover {
        .sele_menu {
          height: 90px;
        }
      }

      .sele_menu::-webkit-scrollbar {
        width: 5px;
      }

      .sele_menu::-webkit-scrollbar-thumb {
        border-radius: 8px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: #5D6B82;
      }

      .sele_menu {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 0;
        overflow-y: scroll;
        border-radius: 5px;
        transition: .5s;
        z-index: 9;
        background-color: rgba(13, 46, 100, 0.6);

        .sele_menu_item {
          width: 100%;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;

          &:hover {
            background-color: rgba(2, 24, 63, 0.5);
          }
        }
      }

      span {
        font-size: 13px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
      }

      img {
        width: 13px;
        height: 7px;
      }
    }
  }

  .chatIcon_list {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    .chatIcon_list_item {
      padding: 14px;
      background: rgba(13, 46, 100, 1);
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      margin-bottom: 11px;
      cursor: pointer;

      &:last-child {
        margin-bottom: 0 !important;
      }

      .chatIcon_list_item_head {
        width: 100%;
        display: flex;
        align-items: center;

        img {
          width: 28px;
          height: 28px;
          margin-right: 10px;
        }

        .head_name {
          font-size: 11px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #FFFFFF;
          padding-right: 9px;
          margin-right: 9px;
          border-right: 1px solid rgba(255, 255, 255, 0.5);
        }

        .head_time {
          font-size: 11px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.5);
        }
      }

      .chatIcon_list_item_info::v-deep {
        width: 100%;
        margin-top: 10px;

        img {
          width: 100%;
        }

        video {
          width: 100%;
        }
      }

      .chatIcon_list_item_pj {
        width: 100%;
        margin-top: 10px;
        cursor: auto;

        .chatIcon_list_item_pj_item {
          width: 100%;
          padding: 8px 14px;
          box-sizing: border-box;
          background: #094074;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          margin-bottom: 9px;
          position: relative;

          &:last-child {
            margin-bottom: 0 !important;
          }

          .chatIcon_list_item_pj_item_pl {
            position: absolute;
            left: 0;
            cursor: auto;
            width: 100%;
            padding: 0 16px;
            box-sizing: border-box;
            // height: 105px;
            background: #FFFFFF;
            border: 1px solid #1784FC;
            border-radius: 6px;
            top: 30px;
            z-index: 9;

            .item_pl_user {
              width: 100%;
              height: 30px;
              line-height: 30px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-bottom: 1px solid rgba(216, 216, 216, 1);

              span {
                &:first-child {
                  font-size: 10px;
                  font-family: Source Han Sans CN;
                  font-weight: 500;
                  color: #1784FC;
                }

                &:last-child {
                  cursor: pointer;
                  font-size: 16px;
                  font-family: Source Han Sans CN;
                  font-weight: 500;
                  color: #000;
                }
              }
            }

            .item_pl_content {
              width: 100%;

              textarea {
                width: 100%;
                height: 50px;
                border: none;
                outline: none;
                font-size: 9px;
                resize: none;
                padding: 10px 0;
                box-sizing: border-box;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #333333;
              }
            }

            .item_pl_caozuo {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              padding-bottom: 8px;
              box-sizing: border-box;

              img {
                flex-shrink: 0;
                width: 13px;
                height: 13px;
                margin-right: 11px;
                cursor: pointer;
              }

              .item_pl_caozuo_btn {
                width: 42px;
                height: 20px;
                line-height: 20px;
                text-align: center;
                background: linear-gradient(0deg, #085EAF, #2791F4);
                border-radius: 10px;
                font-size: 9px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #FFFFFF;
                cursor: pointer;
              }
            }
          }

          .chatIcon_list_item_pj_item_top {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .pj_item_top_user {
              display: flex;
              align-items: center;

              img {
                width: 17px;
                height: 17px;
                border-radius: 50%;
                margin-right: 6px;
              }

              span {
                font-size: 9px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #FFFFFF;
              }
            }

            .pj_item_top_time {
              display: flex;
              align-items: center;

              .pj_item_top_time_a {
                display: flex;
                align-items: center;
                padding-right: 6px;
                margin-right: 6px;
                cursor: pointer;
                border-right: 2px solid rgba(255, 255, 255, 0.3);

                img {
                  width: 9px;
                  height: 8px;
                  margin-right: 3px;
                }

                span {
                  font-size: 9px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  color: #FCB407;
                }
              }

              .pj_item_top_time_b {
                font-size: 9px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #FFFFFF;
              }
            }
          }

          .chatIcon_list_item_pj_item_bottom {
            margin-top: 6px;

            span {
              font-size: 9px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 16px;

              &:first-child {
                color: #FFFFFF;
              }
            }
          }

          .chatIcon_list_item_pj_more {
            margin-top: 10px;
            font-size: 9px;
            color: #fff;
            cursor: pointer;
          }

          .chatIcon_list_item_pj_item_list {
            width: 100%;
            margin-top: 10px;
            padding-left: 20px;
            box-sizing: border-box;

            .chatIcon_list_item_pj_item_list_item {
              width: 100%;
              padding: 8px 0;
              box-sizing: border-box;
              background: #094074;
              border-radius: 10px;
              display: flex;
              flex-direction: column;
              margin-bottom: 5px;
              position: relative;

              &:last-child {
                margin-bottom: 0 !important;
              }

              .chatIcon_list_item_pj_item_pl {
                position: absolute;
                left: 0;
                cursor: auto;
                width: 100%;
                padding: 0 16px;
                box-sizing: border-box;
                // height: 105px;
                background: #FFFFFF;
                border: 1px solid #1784FC;
                border-radius: 6px;
                top: 30px;
                z-index: 9;

                .item_pl_user {
                  width: 100%;
                  height: 30px;
                  line-height: 30px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  border-bottom: 1px solid rgba(216, 216, 216, 1);

                  span {
                    &:first-child {
                      font-size: 10px;
                      font-family: Source Han Sans CN;
                      font-weight: 500;
                      color: #1784FC;
                    }

                    &:last-child {
                      cursor: pointer;
                      font-size: 16px;
                      font-family: Source Han Sans CN;
                      font-weight: 500;
                      color: #000;
                    }
                  }
                }

                .item_pl_content {
                  width: 100%;

                  textarea {
                    width: 100%;
                    height: 50px;
                    border: none;
                    outline: none;
                    font-size: 9px;
                    resize: none;
                    padding: 10px 0;
                    box-sizing: border-box;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #333333;
                  }
                }

                .item_pl_caozuo {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  padding-bottom: 8px;
                  box-sizing: border-box;

                  img {
                    flex-shrink: 0;
                    width: 13px;
                    height: 13px;
                    margin-right: 11px;
                    cursor: pointer;
                  }

                  .item_pl_caozuo_btn {
                    width: 42px;
                    height: 20px;
                    line-height: 20px;
                    text-align: center;
                    background: linear-gradient(0deg, #085EAF, #2791F4);
                    border-radius: 10px;
                    font-size: 9px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #FFFFFF;
                    cursor: pointer;
                  }
                }
              }

              .chatIcon_list_item_pj_item_top {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .pj_item_top_user {
                  display: flex;
                  align-items: center;

                  img {
                    width: 17px;
                    height: 17px;
                    border-radius: 50%;
                    margin-right: 6px;
                  }

                  span {
                    font-size: 9px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #FFFFFF;
                  }
                }

                .pj_item_top_time {
                  display: flex;
                  align-items: center;

                  .pj_item_top_time_a {
                    display: flex;
                    align-items: center;
                    padding-right: 6px;
                    margin-right: 6px;
                    cursor: pointer;
                    border-right: 2px solid rgba(255, 255, 255, 0.3);

                    img {
                      width: 9px;
                      height: 8px;
                      margin-right: 3px;
                    }

                    span {
                      font-size: 9px;
                      font-family: Source Han Sans CN;
                      font-weight: 400;
                      color: #FCB407;
                    }
                  }

                  .pj_item_top_time_b {
                    font-size: 9px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #FFFFFF;
                  }
                }
              }

              .chatIcon_list_item_pj_item_bottom {
                margin-top: 6px;

                span {
                  font-size: 9px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  line-height: 16px;

                  &:first-child {
                    color: #FFFFFF;
                  }
                }
              }
            }
          }
        }
      }

      .chatIcon_list_item_footer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 17px;
        cursor: auto;

        .chatIcon_list_item_footer_left {
          flex: 1;
          display: flex;
          align-items: center;

          .chatIcon_list_item_footer_left_item {
            flex: 1;
            display: flex;
            cursor: pointer;

            img {
              width: 15px;
              height: 14px;
              margin-right: 4px;
            }

            span {
              font-size: 11px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #FFFFFF;
            }
          }
        }

        .chatIcon_list_item_footer_right {
          width: 195px;
          height: 23px;
          padding: 0 1px 0 13px;
          box-sizing: border-box;
          background: #EAF5FF;
          border-radius: 11px;
          margin-left: 21px;
          flex-shrink: 0;
          display: flex;
          align-items: center;

          .inputText {
            flex: 1;
            height: 100%;

            input::-webkit-input-placeholder {
              font-size: 9px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #0361BA;
            }

            input {
              font-size: 9px;
              color: #222222;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0);
              border: none;
              outline: none;
            }
          }

          .caozuo {
            flex-shrink: 0;
            display: flex;
            align-items: center;

            img {
              width: 13px;
              height: 13px;
              margin-right: 8px;
              cursor: pointer;
            }

            .caozuo_btn {
              width: 42px;
              height: 20px;
              line-height: 20px;
              text-align: center;
              background: linear-gradient(0deg, #085EAF, #2791F4);
              border-radius: 10px;
              font-size: 9px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #FFFFFF;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
